import { legacy_createStore, applyMiddleware } from 'redux';
import { composeWithDevTools, devToolsEnhancer } from 'redux-devtools-extension';
import reducer from './reducer'

const store = legacy_createStore(
    // reducer
    reducer,
    // enhancer
    devToolsEnhancer(),
  );

export default store;